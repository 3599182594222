/**
 * responder
 * non-jquery version 0.3
 * 
 * By Dewald Bodenstein
 * May 2017 v0.1
 * July 2018 v0.2
 * May 2020 v0.3 - converted to a class for webpack
 * 
 */
export default {
    items: {},
    resizeTimer: null,
    
    init: function() {
        // add responder event
        var self = this;
        window.addEventListener("resize", function(){self.resize()});
    },
    
    resize: function(){
        var self = this;
        clearTimeout(this.resizeTimer);
        this.resizeTimer = setTimeout(function(){self.respond()},250);
    },
    
    respond: function() {
        for(var i in this.items) {
            this.items[i][0](this.items[i][1]);
        }
    },
    
    /**
     * TODO: what happens to variable scope here?
     * @param {type} identifier
     * @param {type} func
     * @param {type} args
     * @returns {undefined}
     */
    set: function(identifier,func,args) {
        this.items[identifier] = [func, args];
    },
    
    remove: function(identifier) {
        delete this.items[identifier];
    }
}