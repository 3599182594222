/**
 * template
 * 
 * Loads an HTML template file and inserts values into it.
 * 
 * By Dewald Bodenstein
 * May 2017
 * 
 * Will only work locally, no CORS support.
 */
 export default {
    cache: {},
    version: 0,
    /**
     * load
     * 
     * Loads an html text file, on the same server, and then inserts values
     * passed in dataValues in the text. The text is then passed into a callback
     * function.
     * 
     * @param {string} url
     * @param {object} dataValues
     * @param {function} callback
     * @returns {string}
     */
    load: function (url, dataValues, callback) {
        var self = this;
        if(typeof(self.cache[url+"?v="+self.version]) !== 'undefined') {
            
            callback( self.dataValues( self.cache[url+"?v="+self.version], dataValues ) );
            
        } else {
            
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function (e) {
                if (xhr.readyState == 4) {
                    if(xhr.status == 200 || xhr.status == 0) { // 0 for Safari
                        self.cache[url+"?v="+self.version] = xhr.responseText;
                        callback( self.dataValues( xhr.responseText, dataValues ) );
                    } else if(xhr.status == 404) {
                        callback( "404 Not found" );
                    }
                }
                //console.log(xhr.readyState+" "+xhr.status);
            };

            xhr.open("GET", url+"?v="+self.version, true);
            xhr.setRequestHeader('Content-type', 'text/html');
            xhr.send();
            
        }
    },
    
    /**
     * dataValues
     * 
     * Replace variables with their corresponding values in text.
     * Variables are matched to the object's properities by {name} within curly
     * braces.
     * 
     * @param {string} text
     * @param {object} data
     * @returns {string}
     */
    dataValues: function(text, data) {
        for (var x in data) {
            text = text.replace(new RegExp('{'+x+'}', 'g'), data[x]);
        }
        return text;
    }
};