export default {
    /**
     * 
     * @param {object} fields 
     * @param {object} fieldsdata 
     * @returns object
     */
    init: function(fields,fieldsdata) {
        var form_state = {};
        form_state["invalid"] = {}; // initialize validation array
        
        for(var field in fields) {
            var details = fields[field];
            
            if(details.Type.toLowerCase().indexOf("varchar")!==-1 || 
                    details.Type.toLowerCase().indexOf("string")!==-1 || 
                    details.Type.toLowerCase().indexOf("textbox")!==-1 || 
                    details.Type.toLowerCase().indexOf("html")!==-1 || 
                    details.Type.toLowerCase().indexOf("text")!==-1 || 
                    details.Type.toLowerCase().indexOf("color")!==-1 || 
                    details.Type.toLowerCase().indexOf("textarea")!==-1 ||
                    details.Type.toLowerCase().indexOf("ckeditor")!==-1 || 
                    details.Type.toLowerCase().indexOf("email")!==-1 || 
                    details.Type.toLowerCase().indexOf("tel")!==-1 || 
                    details.Type.toLowerCase().indexOf("float")!==-1 || 
                    details.Type.toLowerCase().indexOf("decimal")!==-1 ||
                    details.Type.toLowerCase().indexOf("hidden")!==-1 ||
                    details.Type.toLowerCase().indexOf("username")!==-1 ||
                    details.Type.toLowerCase().indexOf("int")===0 ||
                    details.Type.toLowerCase().indexOf("double")===0 ||
                    details.Type.toLowerCase().indexOf("integer")===0 ||
                    details.Type.toLowerCase().indexOf("address")!==-1 || 
                    details.Type.toLowerCase().indexOf("file")===0 ||
                    details.Type.toLowerCase().indexOf("image")===0 ||
                    details.Type.toLowerCase().indexOf("multi_image")===0 ||
                    details.Type.toLowerCase().indexOf("tinyint")===0 ||
                    details.Type.toLowerCase().indexOf("radio")===0 ||
                    details.Type.toLowerCase().indexOf("boolean")===0) {
                // text
                form_state[field] = (fieldsdata ? fieldsdata[field] : (details["Default"] === null ? "" : details["Default"]));
            } else if(details.Type.toLowerCase().indexOf("password")===0) {
                // password
                form_state[field] = "";
            } else if(details.Type.toLowerCase().indexOf("checkbox")===0 ||
                    details.Type.toLowerCase().indexOf("select")===0 ||
                    details.Type.toLowerCase().indexOf("multi_select")===0) {
                // checkbox or select
                if(fieldsdata) {
                    form_state[field] = (
                            typeof(fieldsdata[field]) === 'string' ? fieldsdata[field].split(",") : 
                            (fieldsdata[field] === null ? [] : fieldsdata[field])
                        );
                } else {
                    form_state[field] = (typeof(details["Default"]) === 'string' ? details["Default"].split(",") : (details["Default"] === null ? "" : details["Default"]));
                    /*foreach($value['Items'] as $itemLabel=>$itemValue) {
                        if($itemValue[1]===true) {
                            Forms::$form_state[$form_name][$key][] = $itemValue[0];
                        }
                    }*/
                }
            } else if(details.Type.toLowerCase().indexOf("timestamp")!==false ||
                    details.Type.toLowerCase().indexOf("date")===0 ||
                    details.Type.toLowerCase().indexOf("datetime")===0) {
                // datepicker
                form_state[field] = (fieldsdata ? fieldsdata[field] : (details["Default"] == "CURRENT_TIMESTAMP" ? new Date() : details["Default"]));
            }
        }
        
        return form_state;
    },

    /**
     * 
     * @param {string} formName 
     * @param {object} fields 
     * @param {bool} validate 
     * @param {bool} skip_null 
     * @returns object
     */
     getValue: function(formName, field, details) {
        
        var $input = $("form[name='"+formName+"'] [name='"+field+"']");
        
        //if(value.Permission !== "edit") {continue;}
        
        if(details.Type.toLowerCase().indexOf("varchar")!==-1 || 
                details.Type.toLowerCase().indexOf("string")!==-1 || 
                details.Type.toLowerCase().indexOf("typeahead")!==-1 ||
                details.Type.toLowerCase().indexOf("textbox")!==-1 || 
                details.Type.toLowerCase() === "color" || 
                details.Type.toLowerCase().indexOf("email")!==-1 || 
                details.Type.toLowerCase().indexOf("tel")!==-1 || 
                details.Type.toLowerCase().indexOf("float")!==-1 || 
                details.Type.toLowerCase().indexOf("decimal")!==-1 ||
                details.Type.toLowerCase().indexOf("double")!==-1 ||
                details.Type.toLowerCase().indexOf("hidden")!==-1 ||
                details.Type.toLowerCase().indexOf("int")===0 ||
                details.Type.toLowerCase() === "image" ||
                details.Type.toLowerCase() === "file_id" ||
                details.Type.toLowerCase() === "image_id") {
            
            // text
            return $input.val();
        } else if(details.Type.toLowerCase().indexOf("password")!==-1) {
            // password
            return $input.val();
        } else if(details.Type.toLowerCase().indexOf("color_display")!==-1) {
            // color display
            return $input.css("background-color");
        } else if(details.Type.toLowerCase().indexOf("text")!==-1 || details.Type.toLowerCase().indexOf("html")!==-1 || details.Type.toLowerCase().indexOf("textarea")!==-1) {
            // textarea
            return $input.val();
        } else if(details.Type.toLowerCase().indexOf("ckeditor")!==-1) {
            // textarea as ckeditor
            
            // ckeditor 4
            if(typeof CKEDITOR !== "undefined") {
                return CKEDITOR.instances[formName+"_"+field].getData();
            }
            else
            // ckeditor 5
            if((typeof ClassicEditor !== "undefined" || typeof InlineEditor !== "undefined") && typeof $input[0].ckeditor !== 'undefined') {
                return $input[0].ckeditor.getData();
            } else {
                console.error("Field "+field+" requires CKEditor 4 or 5")
            }
            
        } else if(details.Type.toLowerCase().indexOf("address")!==-1) {
            // address
            return $input.val();
        } else if(details.Type.toLowerCase().indexOf("select_add")===0) {
            // select add
            //console.log($input)
            return $input.parent(".combobox").parent(".select-add").find("input").val();
        } else if(details.Type.toLowerCase().indexOf("select")===0) {
            // select
            return $input.val();//$input.selectpicker('val');
        } else if(details.Type.toLowerCase().indexOf("tinyint")!==-1 || details.Type.toLowerCase().indexOf("bool")!==-1) {
            // boolean
            return ($input.prop("checked") ? "1" : "0");
        } else if(details.Type.toLowerCase().indexOf("checkbox")!==-1) {
            // checkbox list
            $input = $("form[name='"+formName+"'] [name='"+field+"[]']");
            var value = new Array();
            $input.each(function(){
                if($(this).prop("checked")) {
                    value.push($(this).val());
                }
            });
            return value;
        } else if(details.Type.toLowerCase().indexOf("multi_select")===0) {
            // multi_select list
            var value = new Array();
            //console.log($input,$input.find(":selected"))
            $input.find(":selected").each(function(){
                value.push($(this).val());
            });
            //console.log($input,value)
            return value;
        } else if(details.Type.toLowerCase().indexOf("radio")!==-1) {
            // radio list
            if($("form[name='"+formName+"'] [name='"+field+"']:checked").length) {
                return $("form[name='"+formName+"'] [name='"+field+"']:checked").val();
            } else {
                return null;
            }
            
        } else if(details.Type.toLowerCase().indexOf("timestamp")!==-1 || details.Type.toLowerCase().indexOf("date")!==-1 || details.Type.toLowerCase().indexOf("time")!==-1) {
            // datepicker
            return $input.val();
        }/* else if(stripos($value["Type"],"image")===0) {
            // image upload
            $output.= Render::inputImage($form_name, $field, $label, $fieldsData[$field], (isset($fieldsData["invalid"][$field]) ? $fieldsData["invalid"][$field] : ""), $required, "", $readonly);
        } else if(stripos($value["Type"],"file")===0) {
            // image upload
            $output.= Render::inputFile($form_name, $field, $label, $fieldsData[$field], (isset($fieldsData["invalid"][$field]) ? $fieldsData["invalid"][$field] : ""), $required, "", $readonly);
        }*/ else {
            //$input = form_state[field];
        }
        return null;
    },
    
    /**
     * 
     * @param {string} formName 
     * @param {object} fields 
     * @param {bool} validate 
     * @param {bool} skip_null 
     * @returns object
     */
    getValues: function(formName, fields, validate, skip_null) {
        //console.log(fields)
        var fieldsData = {};
        if(validate === true) {
            fieldsData.invalid = {};
            fieldsData.invalid.invalid_amount = 0;
        }
        for(var field in fields) {
            var details = fields[field];

            fieldsData[field] = this.getValue(formName, field, details);
            
            if(validate===true) {
                var result = this.validateField(formName, field, details, fieldsData[field], skip_null);
                if(result !== true) {
                    fieldsData.invalid[field] = result;
                    fieldsData.invalid.invalid_amount ++;
                }
            }
        }
        return fieldsData;
    },
    
    /**
     * 
     * @param {string} formName 
     * @param {string} field 
     * @param {object} details 
     * @param {string} value 
     * @param {bool} skip_null 
     * @returns bool
     */
    validateField: function(formName, field, details, value, skip_null) {
        //console.log(field+" "+typeof(value))
        if( (typeof(skip_null) === 'undefined' || skip_null === false) && details.Null.toLowerCase().indexOf('no')!==-1 ) {
            // see if valid
            var error = true;
            if(
                typeof(value) !== 'undefined' && value !== null &&
                (
                    ( typeof(value) === "object" && (value.length && value.indexOf('')===-1 && value.indexOf('0')===-1)) ||
                    ( typeof(value) === "string" && value.length) ||
                    ( typeof(value) !== "object" && 
                        (
                            (
                                (details.Type.toLowerCase().indexOf('int')===0 ||
                                details.Type.toLowerCase().indexOf('float')===0 ||
                                details.Type.toLowerCase().indexOf('double')===0) && !isNaN(value)
                            ) || 
                            (
                                (details.Type.toLowerCase().indexOf('file_id')===0 ||
                                details.Type.toLowerCase() === "select") && value != 0 && value != ""
                            ) || value != false
                        )
                    )
                )
                ) {
                error = false;
            }
            if(error ) {
                return {
                    'status':'error',
                    'message':'This field is required'
                };
            }
        }

        /*if(details.Type.indexOf('typeahead')===0) {
            if(!array_key_exists(strtolower(Forms::$form_state[$form_name][$field]), array_change_key_case($fields[$field]['Items']))) {
                Forms::$form_state[$form_name]["invalid"][$field]=array(
                    'status'=>'error',
                    'message'=>'Value not found.'
                );
            } else {
                Forms::$form_state[$form_name][$field] = $fields[$field]['Items'][Forms::$form_state[$form_name][$field]][0];
            }
        }*/
        
        if(typeof(value) !== 'undefined' && value !== null && value.length && details.Type.toLowerCase().indexOf('email')===0) {
            var parts = value.split("@");
            var domParts = (parts.length > 1 ? parts[1].split(".") : [""]);
            if(!(value !== "" && parts.length > 1 && domParts.length > 1 && (
                    parts[1].length >= 1 && domParts[0].length >= 1 && domParts[1].length >= 1))) {
                return {
                    'status': 'error',
                    'message': 'Not a valid email address.'
                };
            }/* else if(stripos($fields[$field]['Extra'],'account')!==false){
                if(count(DS::select("users", "WHERE email=?s",Forms::$form_state[$form_name][$field]))) {
                    Forms::$form_state[$form_name]["invalid"][$field]=array(
                        'status'=>'error',
                        'message'=>'An account with this address already exists.'
                    );
                }
            }*/
        }
        
        if(details.Type.toLowerCase().indexOf('tel')===0) {
            if( (((typeof(skip_null) === 'undefined' || skip_null === false) && details.Null.toLowerCase().indexOf('no')!==-1) || (details.Null.toLowerCase().indexOf('yes')!==-1 && value.length > 0)) && (value.length < 10 || value.length > 12)) {
                return {
                    'status': 'error',
                    'message': 'Not a valid phone number.'
                };
            }
        }
        
        if(details.Type.toLowerCase().indexOf('password')===0) {
            var $confPass = $("form[name='"+formName+"'] [name='confirm_"+field+"']");
            if($confPass.length > 0) {
                if(value !== $confPass.val()) {
                    return {
                        'status': 'error',
                        'message': 'Passwords do not match.'
                    };
                }
            }
        }
        
        if(details.Type.toLowerCase().indexOf('datepicker')===0 && !Date.parse(value)) {
            return {
                'status': 'error',
                'message': 'Not a valid date.'
            };
        }
        
        if(details.Type.toLowerCase().indexOf('int')===0) {
            if(isNaN(value)) {
                return {
                    'status': 'error',
                    'message': 'Not a valid integer value.'
                };
            } else if(typeof(details.Min) !== 'undefined' && value < parseInt(details.Min)) {
                return {
                    'status': 'error',
                    'message': 'Minimum is '+details.Min
                };
            } else if(typeof(details.Max) !== 'undefined' && value > parseInt(details.Max)) {
                return {
                    'status': 'error',
                    'message': 'Maximum is '+details.Max
                };
            }
        }
        
        if(details.Type.toLowerCase().indexOf('float')===0) {
            if(isNaN(value)) {
                return {
                    'status': 'error',
                    'message': 'Not a valid floating point number.'
                };
            } else if(typeof(details.Min) !== 'undefined' && value < parseFloat(details.Min)) {
                return {
                    'status': 'error',
                    'message': 'Minimum is '+details.Min
                };
            } else if(typeof(details.Max) !== 'undefined' && value > parseFloat(details.Max)) {
                return {
                    'status': 'error',
                    'message': 'Maximum is '+details.Max
                };
            }
        }
        
        if(details.Type.toLowerCase().indexOf('double')===0) {
            if(isNaN(value)) {
                return {
                    'status': 'error',
                    'message': 'Not a valid double value.'
                };
            } else if(typeof(details.Min) !== 'undefined' && value < parseFloat(details.Min)) {
                return {
                    'status': 'error',
                    'message': 'Minimum is '+details.Min
                };
            } else if(typeof(details.Max) !== 'undefined' && value > parseFloat(details.Max)) {
                return {
                    'status': 'error',
                    'message': 'Maximum is '+details.Max
                };
            }
        }
        
        return true;
    },

    /**
     * 
     * @param {string} formName 
     * @param {string} field
     * @param {object} details
     * @param {object} value
     * @param {bool} validate 
     */
     updateField: function(formName,field,details,value) {

        var $input = $("form[name='"+formName+"'] [name='"+field+"']");
        
        //if(details.Permission !== "edit") {continue;}
        //Debug.log(details.Type.toLowerCase().indexOf("multi_select"))
        if(details.Type.toLowerCase().indexOf("varchar")!==-1 || 
                details.Type.toLowerCase().indexOf("string")!==-1 || 
                details.Type.toLowerCase().indexOf("textbox")!==-1 || 
                details.Type.toLowerCase().indexOf("email")!==-1 || 
                details.Type.toLowerCase().indexOf("tel")!==-1 || 
                details.Type.toLowerCase().indexOf("float")!==-1 || 
                details.Type.toLowerCase().indexOf("double")!==-1 || 
                details.Type.toLowerCase().indexOf("decimal")!==-1 ||
                details.Type.toLowerCase().indexOf("hidden")!==-1 ||
                details.Type.toLowerCase().indexOf("int")===0 ||
                details.Type.toLowerCase() === "file_id" ||
                details.Type.toLowerCase() === "image_id") {
            // text
            $input.val(value);
        } else if(details.Type.toLowerCase().indexOf("password")!==-1) {
            // password
            $input.val(value);
        } else if(details.Type.toLowerCase().indexOf("text")!==-1 || details.Type.toLowerCase().indexOf("textarea")!==-1 || details.Type.toLowerCase().indexOf("html")!==-1) {
            // textarea
            $input.val(value);
        } else if(details.Type.toLowerCase().indexOf("ckeditor")!==-1) {
            // textarea as ckeditor
            
            // ckeditor 4
            if(typeof CKEDITOR !== "undefined") {
                CKEDITOR.instances[formName+"_"+field].setData(value);
            }
            else
            // ckeditor 5
            if((typeof ClassicEditor !== "undefined" || typeof InlineEditor !== "undefined") && typeof $input[0].ckeditor !== 'undefined') {
                $input[0].ckeditor.setData(value);
            } else {
                console.error("Field "+field+" requires CKEditor 4 or 5")
            }
            
        } else if(details.Type.toLowerCase().indexOf("address")!==-1) {
            // address
            $input.find("input[type=hidden]").val(value);
        } else if(details.Type.toLowerCase().indexOf("select_add")===0) {
            // select add
            //$input.val(value);
        } else if(details.Type.toLowerCase().indexOf("select")===0) {
            // select
            $input.val(value);
        } else if(details.Type.toLowerCase().indexOf("tinyint")!==-1 || details.Type.toLowerCase().indexOf("bool")!==-1) {
            // boolean
            if($input.val()==value) {
                $input.prop("checked",true);
            } else {
                $input.prop("checked",false);
            }
        } else if(details.Type.toLowerCase().indexOf("multi_select")!==-1) {
            // multi_select
            for(var l in details.Items) {
                if(value.indexOf(details.Items[l][0]) !== -1) {
                    $input.find("option").prop("selected",true);
                }
            }
        } else if(details.Type.toLowerCase().indexOf("checkbox")!==-1) {
            // checkbox list
            $input = $("form[name='"+formName+"'] [name='"+field+"[]']");
            $input.each(function(){
                var $this = $(this);
                if(value.indexOf($this.val().toString()) !== -1) {
                    $this.prop("checked",true);
                }
            });
            //$output.= Render::inputCheckbox($form_name, $field, $label, $fieldsData[$field], $value["Items"], (isset($fieldsData["invalid"][$field]) ? $fieldsData["invalid"][$field] : ""), false, "", $readonly);
        } else if(details.Type.toLowerCase().indexOf("radio")!==-1) {
            // radio list
            $("form[name='"+formName+"'] [name='"+field+"'][value='"+value+"']").prop("checked",true);
            
        } else if(details.Type.toLowerCase().indexOf("timestamp")!==-1 || details.Type.toLowerCase().indexOf("date")!==-1 || details.Type.toLowerCase().indexOf("time")!==-1) {
            // datepicker
            $input.val(value);
        }/* else if(stripos($value["Type"],"image")===0) {
            // image upload
            $output.= Render::inputImage($form_name, $field, $label, $fieldsData[$field], (isset($fieldsData["invalid"][$field]) ? $fieldsData["invalid"][$field] : ""), $required, "", $readonly);
        } else if(stripos($value["Type"],"file")===0) {
            // image upload
            $output.= Render::inputFile($form_name, $field, $label, $fieldsData[$field], (isset($fieldsData["invalid"][$field]) ? $fieldsData["invalid"][$field] : ""), $required, "", $readonly);
        }*/ else {
            //$input = value;
        }

        return $input;
    },
    
    /**
     * 
     * @param {string} formName 
     * @param {object} fields 
     * @param {object} form_state 
     * @param {bool} validate 
     */
    updateForm: function(formName,fields,form_state,validate) {
        $("form[name='"+formName+"'] .invalid-feedback").remove();
        for(var field in fields) {
            var $input = this.updateField(formName,field,fields[field],form_state[field]);
            
            if(typeof validate !== 'undefined' && validate !== false) {
                if(typeof(form_state.invalid) !== 'undefined' && typeof(form_state.invalid[field]) !== 'undefined') {
                    $input.addClass("is-invalid");
                    $input.removeClass("is-valid");

                    var $group = $input.closest(".bmd-form-group, .form-group");
                    //console.log($input)
                    if($group.length) {
                        $group.addClass("has-"+form_state.invalid[field].status);
                        $group.append($('<div class="invalid-feedback">').html(form_state.invalid[field].message));
                    } else {
                        //$input.parent(".control-inline").addClass(form_state.invalid[field].status);
                    }
                } else {
                    $input.addClass("is-valid");
                    $input.removeClass("is-invalid");

                    var $group = $input.closest(".bmd-form-group");
                    if($group.length) {
                        $group.removeClass("has-error");
                        //console.log($group.find(".invalid-feedback"))
                        $group.find(".invalid-feedback").remove();
                    } else {
                        //$input.parent(".control-inline").removeClass("has-error");
                    }
                }
            } else {
                $input.removeClass("is-invalid is-valid");
            }
        }
    }
};