export default {
    alert: "<div class='alert alert-dismissable'><button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button></div><br>",
    options: {
        expires: 2000,
        create_callback:null,
        parentTo:null,
        replaceWithId:null,
        id:null
    },
    
    /**
     * 
     * @param {type} text
     * @param {type} alert_type
     * @param {type} options
     * @returns {undefined}
     */
    add: function(text,alert_type,options) {

        var $alert = $(this.alert);
        $alert.first()
            .addClass("alert-"+alert_type+"")
            .append(text);
        
        var removeBR = false;

        var opts = $.extend({},this.options,options);
        if(!opts.parentTo) {
            opts.parentTo = $("#messages");
        } else {
            removeBR = true;
        }
        
        if(opts.id !== null) {
            $(opts.parentTo).find(".alert[data-id='"+opts.id+"']").remove();
        }
        
        if(opts.id !== null) {
            $alert.attr("data-id",opts.id);
        }
        
        if(opts.expires !== 0) {
            $alert.find("button").remove();

            $alert.first().addClass("out");
            setTimeout(function(){
                $alert.removeClass("out").addClass('in');
            },250);

            $alert.timeout = setTimeout(function(){
                $alert.addClass('exit');
            },opts.expires);

            $alert.on('animationend',function(e){
                $alert.remove();
            });
            
            $alert.on("mouseenter",function(){
                clearTimeout($alert.timeout);
            });
            $alert.on("mouseleave",function(){
                $alert.timeout = setTimeout(function(){
                    $alert.addClass('exit');
                },opts.expires);
            });
        }
        
        $(opts.parentTo).append($alert);
        
        if(opts.create_callback !== null) {
            opts.create_callback($alert);
        }

        if(removeBR) {
            $alert.parent().find("> :last-child").remove();
        }
        
    }
    
}